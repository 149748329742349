* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html,
body {
  display: grid;
  height: 100%;
  width: 100%;
  place-items: center;
  background: scroll #dddddd;
}
