.App {
  width: 450px;
  background: #fff;
  border-top: 2px solid rgba(17, 119, 61);
  padding: 0 45px 45px;
}

.App .title {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-top: 30px;
}

.content {
  padding-top: 10px;
  text-align: center;
}

.content .item {
  margin-top: 13px;
  font-size: 15px;
}

.content .item a,
a:visited {
  font-weight: 600;
  color: black;
}

.button {
  height: 50px;
  width: 100%;
  background: rgb(17, 119, 61);
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: 0 !important;
}

.field {
  margin-bottom: 20px;
}

.field label {
  font-size: 18px;
  font-weight: 800;
}

.field input {
  height: 50px;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  border: 1px solid lightgray;
  padding: 15px;
  margin-top: 2px;
  outline: none;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: 0 !important;
}

.field input:focus {
  border: 1px solid black;
}

.check-box {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
/* hide default check box */
.check-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* custom check-box */
.check-box-control {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid black;
}

/* On mouse-over, add a grey background color */
.check-box:hover input ~ .check-box-control {
  background-color: rgb(214, 218, 218);
}

/* When the checkbox is checked, add a blue background */
.check-box input:checked ~ .check-box-control {
  background-color: rgb(11, 90, 255);
}

/* Create the checkmark/indicator (hidden when not checked) */
.check-box-control:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-box input:checked ~ .check-box-control:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-box .check-box-control:after {
  left: 5px;
  top: 0px;
  width: 4px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 450px) {
  .App {
    width: 100vw;
    padding: 0 20px 45px;
  }
}
